import { measure } from '../measure'

export const trackingInit = async (setLoadThirdParty: (value: Promise<() => void>)=>void, userContext: any) => {
  const module = await System.import<VPTracking>('@vp/tracking')

  return measure(async () => {
    const metatags = Array.from(document.querySelectorAll('meta'))
    const { locale: culture, tenant, featureFlags } = userContext

    const trackingFlags = []
    if (featureFlags['tracking_analytics_next']) {
      trackingFlags.push('tracking_analytics_next')
    }

    const trackingConfig : TrackingConfig = {
      tenant,
      culture,
      consentManagerCssClassNames: 'swan',
      scriptNonce: getCspNonce(metatags),
      featureFlags: trackingFlags
    }

    const constentOptions = getConsentOptions(metatags)

    if (constentOptions.includes('suppressReloadOnConsentSubmit')) {
      trackingConfig.suppressReloadOnConsentSubmit = true
    }

    const { tracking, loadThirdParty } = await module.initTracking(trackingConfig)

    tracking.page(getPageName(metatags), {})

    setLoadThirdParty(loadThirdParty)
  }, 'ubik-tracking init')
}

export const getPageName = (metatags: HTMLMetaElement[]) => {
  return metatags.filter((tag) => tag.name === 'pageName')[0]?.content ?? ''
}

export const getCspNonce = (metatags: HTMLMetaElement[]) => {
  return metatags.filter((tag) => tag.name === 'csp-nonce')[0]?.content ?? undefined
}

export const getConsentOptions = (metatags: HTMLMetaElement[]) => {
  const options = metatags.filter((tag) => tag.name === 'vp-consent')[0]?.content ?? ''

  return options.split(',')
}

interface VPTracking {
  initTracking: (config: TrackingConfig) => Promise<{
    tracking: any,
    loadThirdParty: Promise<() => void>
  }>
}

type TrackingConfig = {
  tenant: string,
  culture: string,
  consentManagerCssClassNames: string,
  suppressReloadOnConsentSubmit?: boolean,
  scriptNonce?: string,
  featureFlags: string[]
}
